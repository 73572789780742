<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container family-page-container">
      <div class="header">
        <v-icon>
          <img class="top-icon" alt="" src="@/assets/images/icon-home-circle-32x32.svg">
        </v-icon>
        <div class="title big-text">Rodzina</div>
      </div>
      <div class="family-section-wrapper">
        <div v-for="[relation, members] in sortedFamilyData" :key="relation" class="family-section">
          <h2 class="small-text relation-title">{{ getRelationTitle(relation) }}</h2>
          <div class="family-members">
            <div v-for="member in members" :key="member.id" class="family-member">
              <div class="member-icon">
                <img alt="Member Icon" src="@/assets/images/family_member_icon.png"/>
              </div>
              <div class="member-name">{{ member.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FamilyPage',
  props: {
    familyData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sortedFamilyData() {
      const order = ['marriage', 'child', 'grandChild', 'greatGrandChild', 'parent', 'sibling'];
      const categories = {};

      this.familyData.forEach(member => {
        if (!categories[member.relation]) {
          categories[member.relation] = [];
        }
        categories[member.relation].push(member);
      });

      return Object.entries(categories)
        .sort(([a], [b]) => order.indexOf(a) - order.indexOf(b))
        .filter(([_, members]) => members.length > 0);
    }
  },
  methods: {
    getRelationTitle(relation) {
      const titles = {
        marriage: 'Małżeństwo',
        child: 'Dzieci',
        grandChild: 'Wnuki',
        greatGrandChild: 'Prawnuki',
        parent: 'Rodzice',
        sibling: 'Rodzeństwo'
      };
      return titles[relation] || '';
    }
  }
};
</script>

<style lang="scss" scoped>
.family-page-container {
  max-width: 600px;
  padding: 0 16px;
}

.relation-title {
  margin-bottom: 8px;
}

.header .home-icon {
  font-size: 24px;
  display: inline-block;
}

.profile-page-container {
  padding-top: 48px;
  overflow: hidden;
}

.family-section-wrapper {
  height: auto;
  overflow: auto;
}

.family-section {
  margin-top: 8px;
}

.family-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.family-member {
  margin: 0.3em;
  text-align: center;
  width: 100%; /* Adjusted for better responsiveness */
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  flex: 1;
  white-space: break-spaces;
}

.member-icon {
  margin-bottom: 4px;

  img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 768px) {
  .family-page-container {
    max-width: 600px;
    padding: 0;
  }

  .family-member {
    width: auto;
  }
}

.member-icon {
  margin-bottom: 4px;

  img {
    width: 30px;
    height: 30px;
  }
}
</style>
