<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container">
      <div class="header">
        <v-icon>
          <img class="top-icon" alt="" src="@/assets/images/icon-suitcase-circle-32x32.svg">
        </v-icon>
        <div class="title small-text">Praca</div>
      </div>
      <div v-if="workData.photoUrl" class="photo-frame-wrapper">
        <img
          class="photo-frame is-preview"
          :src="apiUrl + (typeof workData.photoUrl === 'string' ? workData.photoUrl.replace(/\\/g, '/') : '')"
          alt="Work Photo"
          @click="openLightbox()"
        />
      </div>
      <h2 class="big-text">{{ workData.startYear }} - {{ workData.endYear }}</h2>
      <div class="big-text">{{ workData.place }}</div>
      <p class="description">{{ workData.description }}</p>

      <VueEasyLightbox
        :visible="visible"
        :imgs="[currentImg]"
        :index="0"
        @hide="visible = false"
      />
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: 'WorkPage',
  components: {VueEasyLightbox},
  props: {
    workData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      visible: false,
      currentImg: '',
    };
  },
  methods: {
    openLightbox() {
      this.currentImg = this.apiUrl + this.workData.photoUrl;
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-page-container {
  background-image: url('@/assets/images/profile_work_background.png');
  // Inherits styles from memorial-profile.scss
}

.title {
  margin-top: 8px;
  // Inherits small-text styles from memorial-profile.scss
}

.year {
  font-size: 48px; // Large font size for the year
  font-weight: bold;
  margin-top: 16px;
}
</style>
