<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container gallery-container">
      <div class="header">
        <v-icon>
          <img class="top-icon" alt="" src="@/assets/images/icon-image-circle-32x32.svg">
        </v-icon>
        <div class="title small-text">Tak Cię zapamiętamy</div>
      </div>

      <div class="gallery-grid" v-if="galleryData?.items?.length">
        <div v-for="(image, index) in galleryData?.items || []" :key="image.photoUrl" class="gallery-item">
          <img
              v-if="image?.photoUrl"
              :src="getImageUrl(image.photoUrl)"
              class="gallery-image"
              alt="Gallery Image"
              @click="openLightbox(index)"
          />
        </div>
      </div>

      <VueEasyLightbox
        :visible="visible"
        :imgs="images"
        :index="currentImgIndex"
        @hide="visible = false"
      />
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'GalleryPage',
  components: {
    VueEasyLightbox
  },
  props: {
    galleryData: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      visible: false,
      currentImgIndex: 0,
    };
  },
  computed: {
    images() {
      return this.galleryData?.items?.map(item =>
          item.photoUrl ? this.getImageUrl(item.photoUrl) : ''
      ) || [];
    }
  },
  methods: {
    openLightbox(index) {
      this.currentImgIndex = index;
      this.visible = true;
    },
    getImageUrl(photoUrl) {
      if (!photoUrl) return '';
      const cleanPhotoUrl = this.cleanUrl(photoUrl);
      return this.apiUrl.endsWith('/') ? this.apiUrl + cleanPhotoUrl : this.apiUrl + '/' + cleanPhotoUrl;
    },
    cleanUrl(url) {
      return url.replace(/\\/g, '/').replace(/^\//, '');
    }
  },
};
</script>

<style lang="scss" scoped>
.gallery-container {
  height: 100%;
  padding: 64px 0;
}

.header {
  margin-bottom: 16px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  padding: 8px 16px !important;
  list-style: none;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.gallery-item {
  height: fit-content;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  padding: 8px;
  display: flex;

  .gallery-image {
    width: 100%;
    border-radius: 8px;
  }

  &:hover {
    cursor: zoom-in;
  }
}
</style>
