<template>
  <div class="profile-page-container-wrapper">
    <div class="profile-page-container">
      <div class="header">
        <v-icon>
          <img class="top-icon" alt="" src="@/assets/images/icon-star-large-circle-32x32.svg">
        </v-icon>
        <div class="title small-text" v-if="eventDate">{{ eventDate }}</div>
      </div>
      <h2 class="big-text">{{ eventData.year }}</h2>
      <p class="big-text">{{ eventData.title }}</p>
      <p class="description">{{ eventData.description }}</p>
      <div v-if="eventData.photoUrl" class="photo-frame-wrapper">
        <img :src="formattedPhotoUrl" alt="Event Photo" class="photo-frame is-preview" @click="openLightbox()" />
      </div>

      <VueEasyLightbox
        :visible="visible"
        :imgs="[currentImg]"
        :index="0"
        @hide="visible = false"
      />
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'ImportantEventsPage',
  components: {
    VueEasyLightbox,
  },
  props: {
    eventData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      visible: false,
      currentImg: '',
    };
  },
  computed: {
    eventDate() {
      const { month, day } = this.eventData;

      if (day && month) {
        return `${day}.${month}`;
      }

      if (month) {
        return month.toString();
      }

      return '';
    },
    formattedPhotoUrl() {
      return this.apiBaseUrl + this.eventData.photoUrl.replace(/\\/g, '/');
    },
    apiBaseUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  methods: {
    openLightbox() {
      this.currentImg = this.apiUrl + this.eventData.photoUrl;
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
